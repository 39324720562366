import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const LoadingOverlay = () => (
  <div
    className={`fixed top-0 bottom-0 left-0 right-0 opacity-50 bg-black text-white flex space-x-4 items-center justify-center pointer-events-none`}
    style={{ zIndex: 2000 }}
  >
    <FontAwesomeIcon
      icon={faCircle}
      className="animate-pulse text-sm md:text-xl"
    />
    <FontAwesomeIcon
      icon={faCircle}
      className="animate-pulse text-sm md:text-xl"
      style={{ animationDelay: '250ms' }}
    />
    <FontAwesomeIcon
      icon={faCircle}
      className="animate-pulse text-sm md:text-xl"
      style={{ animationDelay: '500ms' }}
    />
  </div>
);
