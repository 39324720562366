import React from 'react';
import { CartProvider } from './CartProvider';
import { NavigationProvider } from './NavigationProvider';
import { YellowBusinessProvider } from './YellowBusinessProvider';

const RootWrapper = ({ element }) => (
  <YellowBusinessProvider>
    <CartProvider>
      <NavigationProvider>{element}</NavigationProvider>
    </CartProvider>
  </YellowBusinessProvider>
);

export default RootWrapper;
