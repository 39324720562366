/* eslint-disable no-undef */

// @ts-ignore
require('./src/css/style.css');
require('react');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { initGA } = require('@websites/utils');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const RootContextWrapper = require('./src/contexts/RootContextWrapper').default;

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    initGA();
  }
};

/**
 * Safari specific issue where going back from a checkout screen back to the Cart page would cause permanent loading of the cart
 * (React useEffect in CartProvider was simply not being called on coming back to cart from the shopify-hosted checkout page)
 * https://stackoverflow.com/questions/54856449/browser-back-button-not-working-for-gatsby-js-project
 */
exports.onInitialClientRender = () => {
  if (
    navigator.userAgent.indexOf('Chrome') === -1
    && navigator.userAgent.indexOf('Firefox') === -1
    && navigator.userAgent.indexOf('Safari') !== -1
  ) {
    window.addEventListener(
      'popstate',
      () => {
        // eslint-disable-next-line no-self-assign
        (window.location.href = window.location.href);
      },
    );
  }
};

exports.wrapRootElement = RootContextWrapper;
