import React from 'react';

export interface INavigationStore {
  lastSelectedCategoryHandle?: string;
  setLastSelectedCategoryHandle: (handle: string) => void;
}

export const NavigationContext = React.createContext<INavigationStore>(
  /**
   * The defaultValue argument is only used when a component does not have a
   * matching Provider above it in the tree. This can be helpful for testing
   * components in isolation without wrapping them.
   *
   * https://reactjs.org/docs/context.html#reactcreatecontext
   */
  {
    lastSelectedCategoryHandle: undefined,
    setLastSelectedCategoryHandle: () => 0,
  },
);

export const NavigationProvider = ({ children }) => {
  const [
    lastSelectedCategoryHandle,
    setLastSelectedCategoryHandle,
  ] = React.useState<string>();

  const navigationStore: INavigationStore = {
    lastSelectedCategoryHandle,
    setLastSelectedCategoryHandle,
  };

  return (
    <NavigationContext.Provider value={navigationStore}>
      {children}
    </NavigationContext.Provider>
  );
};
