import React from 'react';
import { log, logError } from '@websites/utils';
import { getShopifyClient } from '../utils/shopifyClient';

const CHECKOUT_ID_LOCAL_STORAGE_KEY = 'shopifyCheckoutIdV2';
const TOTAL_NUMBER_OF_ITEMS_IN_CART_LOCAL_STORAGE_KEY = 'totalNumberOfItemsInShopifyCartV2';

export interface ICartStore {
  checkoutId?: string; // i.e. won't be defined before the first item is added to the cart
  setAndSaveCheckoutId;
  numCartItems: number;
  addItemsToCartAndSave: (n: number) => void;
  resetLocalStorage: () => void;
}

export const CartContext = React.createContext<ICartStore>(
  /**
   * The defaultValue argument is only used when a component does not have a
   * matching Provider above it in the tree. This can be helpful for testing
   * components in isolation without wrapping them.
   *
   * https://reactjs.org/docs/context.html#reactcreatecontext
   */
  {
    checkoutId: undefined,
    setAndSaveCheckoutId: undefined,
    numCartItems: 0,
    addItemsToCartAndSave: () => console.error(
      'Default addItemsToCart on CartContext is being called. This should never happen.',
    ),
    resetLocalStorage: () => console.error(
      'Default resetLocalStorage on CartContext is being called. This should never happen.',
    ),
  },
);

export const CartProvider = ({ children }) => {
  const [checkoutId, setCheckoutId] = React.useState<string>();
  const [numCartItems, setNumCartItems] = React.useState(0);

  const setAndSaveCheckoutId = (id) => {
    localStorage.setItem(CHECKOUT_ID_LOCAL_STORAGE_KEY, id);
    setCheckoutId(id);
  };

  const addItemsToCartAndSave = (numNewItems: number) => {
    localStorage.setItem(
      TOTAL_NUMBER_OF_ITEMS_IN_CART_LOCAL_STORAGE_KEY,
      `${numCartItems + numNewItems}`,
    );
    setNumCartItems(numCartItems + numNewItems);
  };

  const resetLocalStorage = () => {
    localStorage.removeItem(CHECKOUT_ID_LOCAL_STORAGE_KEY);
    localStorage.removeItem(TOTAL_NUMBER_OF_ITEMS_IN_CART_LOCAL_STORAGE_KEY);
  };

  /**
   * Load cart details from localStorage if they exist
   */
  React.useEffect(() => {
    const storedCheckoutId = localStorage.getItem(
      CHECKOUT_ID_LOCAL_STORAGE_KEY,
    );

    const shopify = getShopifyClient();
    if (storedCheckoutId) {
      // check that the checkout for this checkoutId hasn't already been completed
      shopify.checkout.fetch(storedCheckoutId).then(async (checkout) => {
        if (!checkout) {
          logError(
            `No checkout retrieved for checkout ID = ${
              storedCheckoutId
            }. This is an unexpected state!`,
          );
        }
        if (!checkout || checkout.completedAt) {
          // this checkout has been completed already, time to create a new one
          log(
            'CART PROVIDER - DISCARDING COMPLETED OLD CHECKOUT & CREATING NEW CHECKOUT',
          );
          const newCheckout = await shopify.checkout.create();
          resetLocalStorage();
          setAndSaveCheckoutId(newCheckout.id);
          log('replacement checkout id = ', newCheckout.id);
        } else {
          log('CART PROVIDER - RESTORING EXISTING CHECKOUT');
          // else just read the checkoutId from localstorage and put it in this Cart context's state
          setCheckoutId(storedCheckoutId);
          log('restored checkout id = ', storedCheckoutId);
        }
        const storedNumItemsInCart = localStorage.getItem(
          TOTAL_NUMBER_OF_ITEMS_IN_CART_LOCAL_STORAGE_KEY,
        );

        const totalNumberOfItemsInShopifyCart = storedNumItemsInCart
          ? Number.parseInt(storedNumItemsInCart)
          : 0;

        setNumCartItems(totalNumberOfItemsInShopifyCart);
      });
    } else {
      // create a new checkout id
      shopify.checkout.create().then((checkout) => {
        resetLocalStorage();
        setAndSaveCheckoutId(checkout.id);
        log('no checkout id found so creating a new one = ', checkout.id);
      });
    }
  }, []);

  const cartStore: ICartStore = {
    checkoutId,
    setAndSaveCheckoutId,
    numCartItems,
    addItemsToCartAndSave,
    resetLocalStorage,
  };

  return (
    <CartContext.Provider value={cartStore}>{children}</CartContext.Provider>
  );
};
