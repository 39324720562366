import Client from 'shopify-buy';

let shopifyClient: Client;

/**
 * Singleton instance of shopify client
 */
export const getShopifyClient = () => {
  if (!shopifyClient) {
    shopifyClient = Client.buildClient({
      domain: `${process.env.GATSBY_SHOPIFY_STORE_NAME}.myshopify.com`,
      storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN!,
      apiVersion: '2022-10',
    });
  }
  return shopifyClient;
};
